<template>
  <div class="schedule-row" :class="xScroll>0 ? 'scroll' : ''">
          <div class="schedule-row__workplace" :style="cellWidth == 10 ?{height:'40px'}:''" @contextmenu.prevent="" ref="workplace">      
              <div class="schedule-row__title-block" :style="xScroll>0 ? {transform:`translate3d(${xScroll}px, 0, 0)`} : ''">                        
                <div class="schedule-row__title title position-absolute mt-n2 font-weight-medium">
                  <tooltip-view v-if="!$tools.isEmpty(comPlace.comRoom.comFloor.comBuilding.comBranch)" top :content="comPlace.comRoom.comFloor.comBuilding.comBranch.name">
                  <template v-slot:action>
                    <v-avatar :color="comPlace.comRoom.comFloor.comBuilding.comBranch.color" size="10" style="margin-top:-1px"/>
                  </template>
                </tooltip-view>
                  {{comPlace.comRoom.sid}}
                </div>                      
                <div class="pl-14 schedule-row__subtitle subtitle-2 font-weight-medium">
                    {{comPlace.comRoom.name}} ({{comPlace.name}})</div>
              </div> 
          </div>
          <div class="workday" v-for="day in week" :key="day.date+'_'+comPlace.id" 
          :style="{'width': day.time.length*cellWidth-cellWidth+'px'}"

          @mousemove="$emit('mouseMove',$event,day.time[0],day.time.slice(-1),comPlace,day)"
          
          @contextmenu.prevent=""
          
          @mouseup.left.stop="loadState.isSuccess&&eventStretching!==null ? $emit('cancelStretching',eventStretching) : null"
          @click="eventStretching===null&&eventCopy===null ? userSpecPlanState.modalOpen({
            begin:$tools.dateTimeFormat(day.dateYMD+' '+selectedTime, 'YYYY-MM-DD HH:mm'),
            end:$tools.dateTimeFormat(day.dateYMD+' '+selectedTime, 'YYYY-MM-DD HH:mm', null, 60),
            type:'workVisit',
            comPlace:{
              id:comPlace.id,
              comRoom:{
                id:comPlace.comRoom.id,
              }
            },
            gridMinutes:15,
            userSpec:{
              commentAdmin:null,
            },
            dayTimeBegin: day.time[0],
            dayTimeEnd: day.time[day.time.length-1]
          }) : eventCopy ? $emit('finishCopy',eventCopy) : null"
          
          
          >
      
            
          <div v-if="eventDrag === null&&selectedPlace!=null&&selectedPlace.id === comPlace.id&&selectedDate === day.dateYMD" :style="tooltipStyle" class="tooltip"><span>{{selectedTime}}</span></div>  
      

              <UserSpecPlanCard 
                :events="events" 
                :eventDrag="eventDrag" 
                :eventStretching="eventStretching"
                :eventCopy="eventCopy"
                :comPlace="comPlace" 
                :zoom="zoom" 
                :day="day" 
                :keyPressed="keyPressed"
                :loadState="loadState"
                :userSpecPlanState="userSpecPlanState"
                :dayTimeValidation="dayTimeValidation"
                @cursorStyle="(v)=>$emit('update:cursorStyle',v)"
                @hideTooltip="$emit('hideTooltip')"
                @startCopy="($event,event)=>$emit('startCopy', $event, event)"
                @startDrag="(event)=>$emit('startDrag',event)"
                @startStretching="(event,date,dayTimeEnd)=>$emit('startStretching',event,date,dayTimeEnd)"
              />
              
          </div>
      </div> 
</template>


<script>
//import moment from "moment";
import UserSpecPlanCard from "@/views/UserSpec/_UserSpecPlanCard";
import TooltipView from "@/components/TooltipView";

export default {
  props: ['comPlace','events','cellWidth','sidebarCollapse','week','xScroll','zoom','eventCopy','eventDrag','eventStretching','selectedPlace','keyPressed','loadState','userSpecPlanState','dayTimeValidation','selectedDate','selectedTime','tooltipStyle','cursorStyle'],
  components: {UserSpecPlanCard,TooltipView },
  data: () => ({


  }),
  mounted(){
    this.$emit('xScrollInitial',this.$refs.workplace.getBoundingClientRect().left);
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
  $indent: 200px;
  $border: #e0e0e0 1px solid;
  $cell: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABGCAYAAADb7SQ4AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LmRhYmFjYmIsIDIwMjEvMDQvMTQtMDA6Mzk6NDQgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCAyMi40IChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0MUI2QTBBQzBDRjcxMUVEOUE5NEJDNENFM0IxOUU2OCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0MUI2QTBBRDBDRjcxMUVEOUE5NEJDNENFM0IxOUU2OCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQxQjZBMEFBMENGNzExRUQ5QTk0QkM0Q0UzQjE5RTY4IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQxQjZBMEFCMENGNzExRUQ5QTk0QkM0Q0UzQjE5RTY4Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+5I7JhQAAAEVJREFUeNrszUEBACAMBKCbRda/1ZpoC30IBaiZ2Umqu3PTyiNisVgsFovFYrFYLBaLxWKxWCwWi8VisVgsFov/iI8AAwAdNQSJa6ulbwAAAABJRU5ErkJggg==') top left/ 30px 100% repeat-x;
  %separator {
        content: "";
        position: absolute;      
        transform: translate3d(-1px, 0, 0);
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: 3px 0px 0px 0px rgba(66, 142, 218, 0.3);
        z-index: 9;
  }
  %cell-size {
    width: 30px;
  }

  .schedule-row {
    position: relative;
    border-right: 0;
    display: flex;
    &__workplace {
      display: flex;
      align-items: center;
      font-size: .9rem;
      font-weight: bold;   
      width: $indent;
      min-height: 71px;
      height: auto;
      padding: .5rem;
      border: $border;
      border-right: none;
      border-bottom: none;
    }  
    &__title-block {
        position: absolute;
        background: #ffffff;
        max-width: 190px;
        padding-right: 5px;
        border-radius: 10px;
        z-index: 9999999;
        transition: transform .3s;
        cursor:pointer;
    }

    &__subtitle {
      font-size: .8rem !important;
    }

    &.scroll #{&}__title {
      font-size: .7rem !important;
      line-height: 1.2rem;
      margin-top: 0 !important;
      left: 0;
      top: 2px;
      width: 100%;
      text-align: center;
    }
    &.scroll #{&}__subtitle {
      opacity: 0;
      padding-left: 10px!important;
      padding-top: 10px !important;
    }

    &.scroll #{&}__title-block {
      // box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
      padding: 10px;
      //transition: all .3s;
      width: 60px;
      height: 25px;
      overflow: hidden;
      border: 2px solid #1976d2;

      &:hover {
        width: 100%;
        height: auto;
        .schedule-row__title {
          text-align: left;
          left: 5px;
        }
        .schedule-row__subtitle {
          opacity: 1;
        }
      }
    }  
      
  }

  .workday {
      position: relative;
      display: flex;
      background: $cell; 
      border-top: $border;
      height: auto;
      &::after {
        @extend %separator;
      }
  }  

  .tooltip {
    position:absolute;
    min-width:20px;
    height:100%;
    opacity: 0;
    pointer-events: none;
    display:flex;
    align-items:center;
    font-size: .825rem;
    padding:.825rem .825rem .825rem 1rem;
    transform: translate3d(0, 0, 0);
    z-index: 999;
    &:before {
      content:'';
      position:absolute;
      width:5px;
      height:100%;
      transform: translate3d(-16px, 0, 0);
      background:#1976d2;
    }
    span {
      background: #1976d2;
      color: #ffffff;
      padding: 0.3rem;
      border-radius: 3px;
    }
  }

</style>