<template>

    <div>
    <template v-for="event in events">   
      <div class="schedule-item"
      :key="event.userSpecPlan.id" 
      :class="{ 'schedule-item_withhover': scheduleCardStyle(event).width.replace(/\D/g,'')<175}" 
      v-if="eventDrag!=event&&comPlace.id == event.category && day.dateYMD == $tools.dateTimeFormat(event.start, 'YYYY-MM-DD')
      ||eventDrag==event&&comPlace.id == eventDrag.category && day.dateYMD == $tools.dateTimeFormat(eventDrag.start, 'YYYY-MM-DD')"                                   
      :style="scheduleCardStyle(event, day.time[0])"          
      @mousemove.stop="$emit('hideTooltip')" 
      @mousedown.right.prevent="loadState.isSuccess ? $emit('startDrag',event) : null"  
       
      
      @click.stop="loadState.isSuccess&&keyPressed===false ? userSpecPlanState.modalOpen(Object.assign({}, event.userSpecPlan, {dayTimeEnd: day.time[day.time.length-1],dayTimeBegin: day.time[0]})) : keyPressed ? $emit('startCopy', $event, event) : null"

      >
          <div class="schedule-item__doc">                        
            <v-avatar color="user" size="50">
                <img :src="event.userSpecPlan.userSpec.user.imageUrl"  v-if="event.userSpecPlan.userSpec.user.imageUrl" alt="">
            </v-avatar>

            <div class="ml-2" :class="{ 'hidden': scheduleCardStyle(event).width.replace(/\D/g,'')<100}">
              <div class="schedule-item__doc-name">{{event.userSpecPlan.userSpec.user.lastName}} {{event.userSpecPlan.userSpec.user.firstName}}</div>
              <div class="schedule-item__doc-spec">{{event.userSpecPlan.userSpec.spec.name}}</div>
              <div class="schedule-item__doc-time">{{$tools.dateTimeFormat(event.start, 'HH:mm')}} - {{$tools.dateTimeFormat(event.end, 'HH:mm')}}
                <v-icon dense color="black" v-if="event.userSpecPlan.type === 'eat'">mdi-coffee</v-icon>
                <v-icon dense color="black" v-if="event.userSpecPlan.type === 'break'">mdi-palm-tree</v-icon>
              </div>
            </div>

          </div>
        <div class="schedule-item__stretching"  @mousedown.left.prevent="loadState.isSuccess ? $emit('startStretching',event,day.dateYMD,day.time[day.time.length-1]) : null"></div>
      </div>                       
      
    </template>
  </div>
</template>


<script>
import moment from "moment";

export default {
  props: ['events','comPlace','day' ,'zoom', 'keyPressed', 'loadState', 'userSpecPlanState', 'eventDrag','eventStretching', 'eventCopy', 'dayTimeValidation'],
  data: () => ({


  }),
  methods: {
    scheduleCardOffset(dayStart,userDayStart) {
      let dStart = moment(dayStart, "HH:mm")
      let uDStart = moment(userDayStart, "HH:mm")
      let duration = uDStart.diff(dStart , 'minutes')/this.zoom
      return duration 
    },
    scheduleCardStyle(event, workDayStart) {
      //event.start, event.end, day.time[0], event.userSpecPlan.userSpec.spec.color,event.userSpecPlan.id

      let uStart = moment(event.start, "HH:mm")
      let uEnd = moment(event.end, "HH:mm")
      let duration = uEnd.diff(uStart, 'minutes')/this.zoom

      let left = this.scheduleCardOffset(workDayStart,this.$tools.dateTimeFormat(event.start, 'HH:mm'))
      let width = duration + 'px'
      let borderLeft = '5px solid '+event.userSpecPlan.userSpec.spec.color
      let transition
      let background
      let opacity
      let zindex

      let pointerEvents

      if (event.userSpecPlan.type === 'break'||event.userSpecPlan.type === 'eat'){
        background = '#fdda00';
      }
      if (this.eventDrag != null) {
        pointerEvents = 'none'

        this.$emit('cursorStyle','move');
        transition = 'background .5s linear, opacity .5s linear' 
        zindex = 0   
        if (this.dayTimeValidation == false&&event.start == this.eventDrag.start) {         
          zindex = 99
          background = '#f44336'
          opacity = .8
        }
      }
      else if (this.eventStretching != null) {
        pointerEvents = 'none'

        this.$emit('cursorStyle','ew-resize');
        transition = 'unset'
      }
      else if (this.eventCopy&&event.userSpecPlan.id===this.eventCopy.userSpecPlan.id) {
        background = '#428fda'
      }
      else {
        pointerEvents = 'all'
        this.$emit('cursorStyle','pointer');
        transition = 'all .3s'
      }         
      
      return {
        transform: 'translate3d('+left+'px, 0, 0)',
        width: width,
        borderLeft:borderLeft,
        pointerEvents:pointerEvents,
        background:background,
        opacity: opacity,
        zIndex:zindex,
        transition:transition
      };
    },
    
  }
}
</script>

<style lang="scss" scoped>
    .schedule-item {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      height: 100%;
      width: 0;
      transform: translate3d(0, 0, 0);
      z-index: 99;
      background: rgb(245, 245, 245);
      box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
      cursor: pointer;
      transition: height .3s;
      &__stretching {
        background: #d9d8d8;
        position: absolute;
        top:0;
        right: 0;
        width:5px;
        height:100%;
        cursor:ew-resize;
        &:hover {
          background: #858282;
        }
      }
      &_withhover {
        .hidden {
          opacity: 0;
          position: absolute;
        }
        &:hover {
          box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
          width: 250px !important;
          z-index: 99999;
          .hidden {
           opacity: 1;
           position: relative;
          }
        }
      }
      &:hover {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
      }
      &__doc-time {
        font-weight: bold;
        font-size: .825rem;
      }
      &__doc {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .825rem;
        img{
          object-fit: cover;
        }
      }
    }  
</style>